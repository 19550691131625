import React, { useEffect, useRef, useState } from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import pako from 'pako';
import * as utils from './utils';
import { drawOverlay } from './FeatureSidebar';
import { globals } from './globals';

const FeatureTooltip = ({ featureTooltipObject }) => {
  const canvasRefsPos = useRef([]);
  const canvasRefsNeg = useRef([]);
  const [overlayData, setOverlayData] = useState(null);

  let feature_sidebar_is_open = ![null, undefined].includes(globals.feature_sidebar_op)

  const img_dim = 80


  let num_imgs_to_show = feature_sidebar_is_open ? 6 : Math.floor(utils.interp(window.innerWidth, [1200, 1600], [8,16]))
  num_imgs_to_show = Math.min(featureTooltipObject.image_paths_pos.length, num_imgs_to_show)

  const getImageStyle = () => {
    return { maxHeight: `${img_dim}px`, height: `${img_dim}px` };
  };

  // load overlays data
  useEffect(() => {
    fetch(featureTooltipObject.overlaysPath)
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => {
        const uint8Array = new Uint8Array(arrayBuffer);
        const decompressed = pako.ungzip(uint8Array, { to: 'string' });
        const overlays = JSON.parse(decompressed);
        setOverlayData(overlays);
      });
  }, [featureTooltipObject.overlaysPath]);

  // draw the overlays on the canvases
  useEffect(() => {
    // console.log("drawing overlays")
    if (!overlayData) return;

    canvasRefsPos.current.forEach((canvas, index) => {
      drawOverlay(canvas, overlayData, index, 0, capture_config);
    });

    if (featureTooltipObject.showNegImgs){
      canvasRefsNeg.current.forEach((canvas, index) => {
        drawOverlay(canvas, overlayData, index, 2, capture_config);
      });
    }

  }, [overlayData]);

  const limitedImagesPos = featureTooltipObject.image_paths_pos.slice(0, num_imgs_to_show);
  const limitedImagesNeg = featureTooltipObject.image_paths_neg.slice(0, num_imgs_to_show);
  
  let isAttnHead = overlayData?.fn_type === "attnMatrix"
  let title = `${featureTooltipObject.node_id} — ${isAttnHead ? "attn head " : "channel "}${featureTooltipObject.channel_ix}`

  let show_negatives = featureTooltipObject.showNegImgs && !feature_sidebar_is_open

  let capture_config = featureTooltipObject.capture_config
  
  function get_images_and_overlays(imgPaths, canvasRefs){
    // console.log("get images and overlays called from tooltip")
    return imgPaths.map((src, index) => (
      <div key={index} style={{ position: 'relative' }}>
        <img
          src={src}
          alt={src}
          style={getImageStyle()}
        />
        <div style={{position: "relative", height:`${img_dim}`, width:`${img_dim}`}}>
          <img
            src={src}
            alt={src}
            style={{ 
              ...getImageStyle(),
              position: 'absolute',
              top: 0,
              left: 0,
              opacity: 0.25,
              filter: 'saturate(0)',
            }}
          />
          <canvas
            ref={el => canvasRefs.current[index] = el}
            style={{
              width: '100%',
              height: '100%',
              pointerEvents: 'none'
            }}
          />
        </div>
      </div>
    ))
  }
  return (
    <Card style={{
      width:`${(img_dim+8)*num_imgs_to_show+(feature_sidebar_is_open ? 22 : 100)}px`, // +100 when text
      userSelect: "none",
      pointerEvents: "none",
      }}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <p style={{
              fontSize:'14px',
              margin: '0px',
              padding: '0px 0px 10px 0px'
            }}>{title}</p>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
{!feature_sidebar_is_open && <p style={{ width:'70px', fontSize:'12px' }}>
            <span style={{ color: 'red' }}>Max</span> dataset {overlayData?.fn_type === "attnMatrix" ? "QK matches" : "activations"}
          </p>}
          {get_images_and_overlays(limitedImagesPos, canvasRefsPos)}
        </Box>
        {
          show_negatives &&
          (<>
          <Box sx={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)', my: 2 }} />
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {!feature_sidebar_is_open &&  <p style={{ width:'70px', fontSize:'12px'}}>
              <span style={{ color: 'blue' }}>Min</span> dataset {overlayData?.fn_type === "attnMatrix" ? "QK matches" : "activations"}
            </p>}
            {get_images_and_overlays(limitedImagesNeg, canvasRefsNeg)}
          </Box>
          </>)
        }
        {overlayData?.fn_type === "attnMatrix" && (
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <div style={{ 
                width: '12px', 
                height: '12px', 
                backgroundColor: 'rgba(0, 255, 0, 0.7)', 
                borderRadius: '50%' 
              }}/>
              <Typography variant="caption">Query (Q)</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <div style={{ 
                width: '12px', 
                height: '12px', 
                backgroundColor: 'rgba(255, 0, 255, 0.7)', 
                borderRadius: '50%' 
              }}/>
              <Typography variant="caption">Key (K)</Typography>
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default FeatureTooltip;