import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import * as utils from './utils'

function linspace(start, stop, num) {
    const step = (stop - start) / num;
    return Array.from({ length: num }, (_, i) => start + step * i);
}

const HistogramChart = ({ 
    percentile_values,
    hist_bins, 
    channel_std,
    channel_mean,
    isAttnHead,
    capture_config
 }) => {
    let percentile_values_granular = percentile_values

    let pdf_values = percentile_values_granular.map((v,ix)=>{
        let pdf_value = ix===0 ? 0 : v - percentile_values_granular[ix-1]
        return pdf_value
    })
    // console.log("XXXXXXXXXXXXXXXXXX", hist_bins, channel_std)
    // Create chart data from the CDF values
    let neutral_rgb = 'rgb(160,160,160)'
    const chartData = hist_bins.map((x, i) => {
        let rgb_str
        if (isAttnHead) {
            rgb_str = neutral_rgb
        } else if (capture_config.mean_center) {
            let rgb = utils.rgb_from_actual_z((x - channel_mean)/channel_std)
            // rgb = utils.rgb_from_z((x - channel_mean)/channel_std)
            rgb_str = `rgb(${rgb.r},${rgb.g},${rgb.b})`
            // rgb_str = neutral_rgb
        } else {
            let rgb = utils.rgb_from_z(x/channel_std)
            rgb_str = `rgb(${rgb.r},${rgb.g},${rgb.b})`
        }
        return {
            bin: (x).toFixed(2),
            value: pdf_values[i] +.0003, // tiniest little padding to show something, otherwise invisible. though technically inaccurate. 
            color: rgb_str
        } 
    });


    return (
        <div>
            <BarChart
                width={300}
                height={200}
                data={chartData}
                margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 14,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                    dataKey="bin" 
                    tick={{ fontSize: 12 }}
                    // label={{ value: 'raw activation values', position: 'top right' }}
                />
                {/* <YAxis
                    label={{ value: 'Cumulative Probability', angle: -90, position: 'left' }}
                /> */}
                {/* <Tooltip /> */}
                <Bar 
                    dataKey="value" 
                    fill="#8884d8"
                    stroke="none"
                    isAnimationActive={false}
                >
                    {chartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </Bar>
            </BarChart>
        </div>
    );
};

export default HistogramChart;