import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, InputLabel, Select, MenuItem, Box } from '@mui/material';
import * as utils from './utils'


import { useLocation, useNavigate } from 'react-router-dom';

import { styled, lighten, darken } from '@mui/system';

import DraggableWindow from './DraggableWindow';
import { Settings } from 'lucide-react';

import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Radio, { radioClasses } from '@mui/joy/Radio';

import RadioGroup from '@mui/joy/RadioGroup';
import Switch from '@mui/joy/Switch';

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: '#1976d2',
  backgroundColor: lighten('#42a5f5', 0.85),
  ...theme.applyStyles('dark', {
    backgroundColor: darken('#1976d2', 0.8),
  }),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

let prev_selected_model = null

const Sidebar = ({ onFilterChange, setDropdownValue, dropdownValue, 
                    setActsDisplayStatus, actsDisplayStatus,
                    actsDisplayOptions,
                  depthValues, overviewStats, 
                  cameraLoc, setCameraLoc, activationsStatus, setActivationsStatus, featureSidebarChannel, setFeatureSidebarChannel // url tracking
                }) => {
    
    const navigate = useNavigate();
    const query = useQuery();

    const [modelOptions, setModelOptions] = useState(null);
    let default_model = "efficientnet_b0"
    const [selectedModel, setSelectedModel] = useState(query.get("model") || default_model);
    
    // camera position from url
    let xPos = query.get("cx")
    let yPos = query.get("cy")
    let zoom = query.get("zoom")

    // expanded acts from url
    let expandedActs = query.get("acts")

    // feature sidebar from url
    let featureSidebar = query.get("fs")


    const [colorBy, setColorBy] = React.useState('none');
    const colorByChange = (event) => {
      console.log(event.target.value)
      setColorBy(event.target.value);
      onFilterChange({ nodesColorBy: event.target.value });
    };

    let title_font_size = Math.floor(utils.interp(window.innerWidth, [1200, 1600], [18,26]))

    function _actsDisplayStatusChange(event) {
      let actsDisplay = event.target.value
      setActsDisplayStatus(actsDisplay)
      onFilterChange({ actsDisplayStatus: actsDisplay })
    }
    const actsDisplayStatusChange = utils.thinkingFn(_actsDisplayStatusChange, "changing activations display status...")


    //////////////////////////////////
    // depth dropdown
    const _handleDropdownChange = (event) => {
        setDropdownValue(event.target.value);
        onFilterChange({ dropdownValue: event.target.value });
    };
    const handleDropdownChange = utils.thinkingFn(_handleDropdownChange, "setting depth...")

    function _onSelectModel(model_entry) {
        let model_name = model_entry.name
        setSelectedModel(model_name)
    }
    const onSelectModel = utils.thinkingFn(_onSelectModel, "loading new model into darkspark viewer...")


    useEffect(() => {
      // only called on initial load of model.
      // also need to ensure our saved url params are passed in when making

      let url_path = `?model=${selectedModel}`
      let newFilters = { 'selectedModelPath': utils.get_file_path(`data/model_specs/${selectedModel}.json.gz`) }
      
      if ((prev_selected_model===selectedModel) || prev_selected_model==null) {
        // only do these if the same model
        // New models, pos and acts info not relevent anymore, so lose it
        if (xPos && yPos) {
          url_path += `&cx=${xPos}&cy=${yPos}&zoom=${zoom}`
          newFilters.xPos = parseFloat(xPos)
          newFilters.yPos = parseFloat(yPos)
          newFilters.zoom = parseFloat(zoom)
        }
        if (expandedActs) {
          url_path += `&acts=${expandedActs}`
          newFilters.expandedActs = expandedActs.split(",")
        }
        if (featureSidebar) {
          url_path += `&fs=${featureSidebar}`
          newFilters.featureSidebar = featureSidebar
        }
      } else {
        setCameraLoc(null)
        setActivationsStatus(null)
        setFeatureSidebarChannel(null)
      }
      prev_selected_model = selectedModel

      navigate(url_path, { replace: true });

      // send model path to main panel
      onFilterChange(newFilters)

    }, [selectedModel, navigate]);


    useEffect(() => {
      // just used for updating url, doesn't remake anything in the app
      // called often

      let url_path = `?model=${selectedModel}`
      
      if (cameraLoc) { // when user scrolls or zooms, this will update
        url_path += `&cx=${cameraLoc.xPos}&cy=${cameraLoc.yPos}&zoom=${cameraLoc.zoom}`
      } else if (xPos) { // when loading page, if location info available in url then use it
        url_path += `&cx=${xPos}&cy=${yPos}&zoom=${zoom}`
      }

      if (activationsStatus) { // when user opens or closes actgrids, update url
        if (activationsStatus.expanded_actgrids.length===0) {
          // user has closed all actgrids
          setActivationsStatus(null)
          expandedActs = null
        } else {
          url_path += `&acts=${activationsStatus.expanded_actgrids}`
        }
      } else if (expandedActs) { // when loading page, if acts info in url, use it. . Same as prev fn
        url_path += `&acts=${expandedActs}`
      }

      if (featureSidebarChannel) { // when user opens or closes sidebar, update url
        if (featureSidebarChannel==="removed") {
          // user closed feature sidebar
          featureSidebar = null
          setFeatureSidebarChannel(null)
        } else {
          url_path += `&fs=${featureSidebarChannel}`
        }
      } else if (featureSidebar) { // when loading page, if sidebar info in url, use it. Same as prev fn
        url_path += `&fs=${featureSidebar}`
      }

      navigate(url_path, { replace: true });

    }, [cameraLoc, activationsStatus, featureSidebarChannel]);


    // load model specs table of contents
    useEffect(() => {
        fetch(utils.get_file_path(`data/model_specs_overview.json`)) // overview index not compressed
          .then(response => response.json())
          .then(data => {
              let data_as_array = Object.keys(data).map(model_name => data[model_name])
              const transformers_str_w_emoji = '\u{1F917} Transformers'
              const diffusers_str_w_emoji = '\u{1F917} Diffusers'
              data_as_array.forEach(d => {
                  d.library = d?.trace_metadata?.library ?? "none"
                  d.library = d.library === "transformers" ? transformers_str_w_emoji : d.library
                  d.library = d.library === "diffusers" ? diffusers_str_w_emoji : d.library
              })
              data_as_array.sort((a, b) => {
                  const libraryCompare = a.library.localeCompare(b.library, undefined, { sensitivity: 'base' });
                  
                  if (libraryCompare !== 0) {
                    return libraryCompare;
                  }
                  
                  // Fallback to sorting alphabetically by another field within the same library
                  return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
              });
                
              setModelOptions(data_as_array);
          });

    }, []);

    let colorByOptions = [
      {label: "default", value:"none"},
      {label: "n_params", value:"n_params"},
      {label: "latency", value:"latency"},
      {label: "memory (incremental)", value:"incremental_memory_usage"},
      {label: "memory (total)", value:"max_memory_allocated"},
    ]

    let selectStyling = {
      '& .MuiSelect-select': {
        padding: '8px 14px', // Further reduce padding if needed
      },
    }

    return (
        <div>
              <DraggableWindow 
                          title="control panel" 
                          initialPosition={{ x: 20, y: 140 }}
                          collapsedIcon={Settings}
                          initiallyCollapsed={true}
                        >
                {modelOptions && 
                <FormControl>
                  <InputLabel>Model</InputLabel>
                  <Autocomplete id="model" 
                      value={modelOptions.find(option => option.name === selectedModel) || null}
                      onChange={(event, newValue) => onSelectModel(newValue)}
                      disableClearable // otherwise have x to clear it, which breaks things when done bc name is null
                      options={ modelOptions }
                      getOptionLabel={(option) => option.name}
                      groupBy={(option) => option.library}
                      sx={{ 
                        width: '100%',
                        '& .MuiInputBase-root': {
                          padding: '1px 8px ', // eyeballing to keep same as selectStyling
                        },
                      }}
                      renderInput={(params) => <TextField {...params} />} 
                      renderGroup={(params) => (
                          <li key={params.key}>
                            <GroupHeader>{params.group}</GroupHeader>
                            <GroupItems>{params.children}</GroupItems>
                          </li>
                      )}
                  />
                </FormControl>
                }
                <br></br>
                <FormControl>
                  <InputLabel>Collapse to depth</InputLabel>
                  <Select
                    value={dropdownValue}
                    onChange={handleDropdownChange}
                    sx={selectStyling}
                  >
                    {depthValues.map((i) => (
                      <MenuItem key={i} value={i}>{i}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br></br>

                      {/* removing for now bc would need to update colors, sizing for it to be helpful */}
                {/* <FormControl>
                  <InputLabel>Nodes color by</InputLabel>
                  <Select
                    value={colorBy}
                    // label="Nodes color by."
                    onChange={colorByChange}
                    sx={selectStyling}
                  >
                    {colorByOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br></br> */}

                {/* not allowing to load all at once, though admittedly it is convenient. Can let back in */}
                {/* 
                <FormControl>
                  <InputLabel>Activations display</InputLabel>
                  <RadioGroup
                    orientation="horizontal"
                    aria-label="Alignment"
                    name="alignment"
                    variant="outlined"
                    value={actsDisplayStatus}
                    onChange={actsDisplayStatusChange}
                    sx={{ display: 'inline-flex', gap: '8px', width: 'fit-content' }}
                  >
                    {actsDisplayOptions.map((item) => (
                      <Box
                        key={item.value}
                        title={item.tooltip}
                        sx={() => ({
                          position: 'relative',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: 80, // Increased width slightly to accommodate word wrap
                          height: 36, // Increased height slightly to accommodate word wrap
                          border: '1px solid #e0e0e0', // Light outline
                          borderRadius: '4px', // Rounded corners for the outline
                          overflow: 'hidden', // Ensure content doesn't overflow
                        })}
                      >
                        <Radio
                          value={item.value}
                          disableIcon
                          overlay
                          label={item.label}
                          variant={actsDisplayStatus === item.value ? 'solid' : 'plain'}
                          slotProps={{
                            input: { 'aria-label': item.value },
                            action: {
                              sx: { 
                                borderRadius: 0, 
                                transition: 'none',
                                height: '100%', // Ensure the action covers the entire box
                              },
                            },
                            label: { 
                              sx: { 
                                fontSize: '0.75rem', // Smaller text
                                lineHeight: 1.2, // Adjusted line height for better readability
                                padding: '4px', // Added padding inside the label
                                textAlign: 'center', // Center align text
                                wordBreak: 'break-word', // Allow word wrapping
                              } 
                            },
                          }}
                        />
                      </Box>
                    ))}
                  </RadioGroup>
                </FormControl> */}

                
                {/* Home Icon with Tooltip */}
                {/* <Tooltip title="Return to DarkSpark Home" arrow>
                  <IconButton component={Link} to="/" aria-label="return to home">
                    <HomeIcon />
                  </IconButton>
                </Tooltip> */}
              </DraggableWindow>


            
        <div style={{ padding: '20px', 
                        fontFamily: 'Arial, sans-serif',
                        position: 'absolute',
                        left: '36px',
                        bottom: '36px',
                        width: 'auto',
                        maxWidth: "360px",
                        height: 'auto',
                        zIndex: 3,
                        fontSize: 'xx-large',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        
                        }}>
            {/* Title Row */}
            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                <h1 style={{ fontSize: `${title_font_size}px`, margin: 0 }}>{selectedModel.toUpperCase()}</h1>
            </div>
            
            {/* Content Row */}
            <div style={{ display: 'flex', 
                        justifyContent: 'space-between',
                        gap: "20px",
                         }}>
                {/* First Column */}
                <div style={{ flex: 1, textAlign: 'center' }}>
                  <div style={{ fontSize: '12px', color: 'gray' }}>n_params</div>
                  <div style={{ fontSize: '18px', fontWeight: 'bold' }}>{utils.formatNumParams(overviewStats.total_params)}</div>
                </div>
                
                {/* Second Column */}
                <div style={{ flex: 1, textAlign: 'center' }}>
                  <div style={{ fontSize: '12px', color: 'gray' }}>total time</div>
                  <div style={{ fontSize: '18px', fontWeight: 'bold' }}>{utils.formatLatency(overviewStats.total_latency)}</div>
                </div>
                
                {/* Third Column */}
                <div style={{ flex: 1, textAlign: 'center' }}>
                  <div style={{ fontSize: '12px', color: 'gray' }}>peak gpu memory</div>
                  <div style={{ fontSize: '18px', fontWeight: 'bold' }}>{utils.formatMemorySize(overviewStats.max_memory_allocated)}</div>
                </div>
            </div>
        </div>

    </div>

    );
};

export default Sidebar;