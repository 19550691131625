import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { app, analytics } from './firebase';
import React, { useState, useEffect, useRef } from 'react';
import Sidebar from './sidebar';
import MainPanel from './main_panel';
import LandingPage from './landing_page';
import * as utils from './utils'
import CircularProgress from '@mui/joy/CircularProgress';
import { logEvent } from 'firebase/analytics';
import { globals } from './globals';

import { BrowserRouter as Router, Route, useLocation, useNavigate, Routes } from 'react-router-dom';


const App = () => {
  const [filters, setFilters] = useState({});
  const [dropdownValue, setDropdownValue] = useState(''); // depth
  const [depthValues, setDepthValues] = useState([]); // depth choices
  const [overviewStats, setOverviewStats] = useState({'total_params':0, 'total_latency':0, 'max_memory_allocated':0});

  const [helpInformation, setHelpInformation] = useState("loading model into darkspark viewer...");
  const [isThinking, setIsThinking] = useState(true);

  const [actsDisplayStatus, setActsDisplayStatus] = React.useState('volumes');

  const [actsDisplayOptions, setActsDisplayOptions] = React.useState([]);

  const [cameraLoc, setCameraLoc] = React.useState(null);
  const [activationsStatus, setActivationsStatus] = React.useState(null);
  const [featureSidebarChannel, setFeatureSidebarChannel] = React.useState(null);

  const [isMobileDevice, setIsMobileDevice] = useState(false);

  // set up logger and timer
  useEffect(() => {
    // logging
    const logger = new Logger(analytics)
    globals.logger = logger
    globals.session_aggregator = new Aggregator()

    // timing
    const timer = {
      start(label) {
        this[label] = performance.now();
      },
      
      end(label) {
        if (!this[label]) return;
        const duration = performance.now() - this[label];
        globals.logger.log('performance', {
          label,
          duration_ms: Math.round(duration),
        });
        delete this[label];
      }
    };
    globals.timer = timer

  }, []);

  // end of session logging
  const sessionStartTime = useRef(Date.now());
  const lastActivityTime = useRef(Date.now());
  useEffect(() => {
    const SESSION_TIMEOUT_MS = 30 * 60 * 1000; // 30 minutes
    let timeoutId;

    function get_session_info() {
      return {
        avg_fps: globals.averageFps,
        feature_tooltip_views: globals.session_aggregator.events["viewed feature tooltip"]
      }
    }

    // Check for timeout every minute
    const checkTimeout = () => {
      const now = Date.now();
      const timeSinceLastActivity = now - lastActivityTime.current;

      if (timeSinceLastActivity >= SESSION_TIMEOUT_MS) {
        const sessionLength = lastActivityTime.current - sessionStartTime.current;
        globals.logger.log('session_end', {
          reason: 'timeout',
          session_length_sec: Math.round(sessionLength/1000),
          ...get_session_info()
        });
        
        // // Reset for new session
        // sessionStartTime.current = now;
        // lastActivityTime.current = now;
      } else {
        // stop checking if timed out
        timeoutId = setTimeout(checkTimeout, 60000); // Check every minute
      }
    };

    // Update last activity time on any user interaction
    const updateActivity = () => {
      lastActivityTime.current = Date.now();
    };
    // Log session end when user leaves
    const handleBeforeUnload = () => {
      const sessionLength = Date.now() - sessionStartTime.current;
      globals.logger.log('session_end', {
        reason: 'page_unload',
        session_length_sec: Math.round(sessionLength/1000),
        ...get_session_info()
      });
    };

    // Start timeout checker
    timeoutId = setTimeout(checkTimeout, 600);

    // Listen for user activity
    document.addEventListener('mousedown', updateActivity);
    document.addEventListener('keydown', updateActivity);
    document.addEventListener('touchstart', updateActivity);
    document.addEventListener('scroll', updateActivity);
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
        clearTimeout(timeoutId);
        document.removeEventListener('mousedown', updateActivity);
        document.removeEventListener('keydown', updateActivity);
        document.removeEventListener('touchstart', updateActivity);
        document.removeEventListener('scroll', updateActivity);
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
  }, [])

  useEffect(() => {
    const checkDevice = () => {
      setIsMobileDevice(window.innerWidth < 768);
    };

    checkDevice();
    window.addEventListener('resize', checkDevice);
    return () => window.removeEventListener('resize', checkDevice);
  }, []);

  if (isMobileDevice) {
    globals.logger.log('mobile_blocker');
    return <MobileBlocker />;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} /> 
        <Route 
          path="/models" 
          element={
            <div style={{ display: 'flex', 
                          height: '100%', 
                          width: '100%',
                          WebkitTouchCallout: 'none',
                          WebkitUserSelect: 'none',
                          userSelect: 'none',
                          }}>
              <div style={{ userSelect: 'none' }}>
                <Sidebar onFilterChange={setFilters}
                          setDropdownValue={setDropdownValue}
                          dropdownValue={dropdownValue}
                          depthValues={depthValues}
                          overviewStats={overviewStats}
                          setActsDisplayStatus={setActsDisplayStatus}
                          actsDisplayStatus={actsDisplayStatus}
                          actsDisplayOptions={actsDisplayOptions}
                          cameraLoc={cameraLoc}
                          setCameraLoc={setCameraLoc} // url tracking
                          activationsStatus={activationsStatus}
                          setActivationsStatus={setActivationsStatus}
                          featureSidebarChannel={featureSidebarChannel}
                          setFeatureSidebarChannel={setFeatureSidebarChannel}
                          />
              </div>
              <div className="main-panel" style={{overflow:"hidden"}}>
                <MainPanel filters={filters} setDropdownValue={setDropdownValue} 
                                              setDepthValues={setDepthValues} 
                                              setOverviewStats={setOverviewStats}
                                              setHelpInformation={setHelpInformation}
                                              setActsDisplayStatus={setActsDisplayStatus}
                                              setActsDisplayOptions={setActsDisplayOptions}
                                              setIsThinking={setIsThinking}
                                              setCameraLoc={setCameraLoc} // url tracking
                                              setActivationsStatus={setActivationsStatus} // url tracking
                                              setFeatureSidebarChannel={setFeatureSidebarChannel}
                                              />
              </div>
              <div style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                color: 'grey',
                background: 'transparent',
                zIndex: 100,
                width: '200px', 
                fontSize: '12px',
                userSelect: 'none',
                padding: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <div style={{
                  wordWrap: 'break-word',
                  flex: 1,
                  marginRight: '10px'
                }}>
                  {helpInformation}
                </div>
                {isThinking && 
                  <CircularProgress variant="solid" color="neutral" size='lg'/>
                }
              </div>

            </div>
        } />
      </Routes>
    </Router>
  );
};

export default App;


const MobileBlocker = () => {
  return (
    <div style={{
      position: 'fixed',
      inset: 0,
      backgroundColor: 'white',
      padding: '2rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center'
    }}>
      <h1 style={{
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginBottom: '1rem'
      }}>
        Desktop Version Only
      </h1>
      <p style={{ marginBottom: '1rem' }}>
        Darkspark requires a larger screen. Please access it from a tablet or desktop device.
      </p>
      <p style={{ 
        fontSize: '0.875rem',
        color: '#666'
      }}>
        Minimum supported width: 768px
      </p>
    </div>
  );
};

const log_frequencies = {
  "viewed feature tooltip": [3,10,50,100]
}

class Aggregator {
  constructor() {
    this.events = {};
  }

  logEvent(eventName) {
    if (!(eventName in this.events)) {
      this.events[eventName] = 0;
    }
    this.events[eventName]++;
    this.log_if_necessary(eventName)
  }
  log_if_necessary(eventName) {
    let c = this.events[eventName]
    if (log_frequencies[eventName].includes(c)){
      globals.logger.log(eventName, {"count":c})
    }
  }
}

class Logger {
  constructor(analytics) {
    this.analytics = analytics;
    this.isDevelopment = window.location.hostname === 'localhost';
    this.timezone = this.getTimezone()
    this.sessionId = this.generateSessionId();
    this.logDeviceInfo();
  }

  log(eventName, params = {}) {
    let fullParams =  {
      ...params,
      session_id: this.sessionId
    }
    if (this.isDevelopment) {
      console.log(`[EVENT] ${eventName}:`, fullParams);
      return;
    } else {
      logEvent(this.analytics, eventName, fullParams);
    }
  }

  getTimezone() {
    try {
      return Intl.DateTimeFormat().resolvedOptions().timeZone?.replace(/\//g, '-') || 'unknown';
    } catch {
      return 'unknown';
    }
  }
  
  generateSessionId() {
    const timestamp = Date.now();
    const random = Math.random().toString(36).substring(2, 9);
    return `${timestamp}-${this.timezone}-${random}`;
  }

  getDeviceInfo() {
    const info = {
      // Session
      session_id: this.sessionId,
      timestamp: new Date().toISOString(),

      // Browser & OS
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      language: navigator.language,
      languages: navigator.languages,
      cookieEnabled: navigator.cookieEnabled,
      timezone: this.timezone,

      // Screen & Window
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      screenColorDepth: window.screen.colorDepth,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      devicePixelRatio: window.devicePixelRatio,

      // Connection
      connectionType: navigator.connection?.type,
      connectionSpeed: navigator.connection?.effectiveType,

      // Memory & Performance
      deviceMemory: navigator.deviceMemory,
      hardwareConcurrency: navigator.hardwareConcurrency,
    };

    // GPU Info if available
    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    if (gl) {
      const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
      if (debugInfo) {
        info.gpuVendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
        info.gpuRenderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
      }
    }

    return info;
  }

  logDeviceInfo() {
    const deviceInfo = this.getDeviceInfo();
    this.log('user_info', deviceInfo);
  }
}

