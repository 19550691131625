import React, { useEffect, useRef, useState } from 'react';
import * as utils from './utils';
import { globals } from './globals';

const FeatureDescriptionTextEditor = ({
    tensorId,
    channelIx,
}) => {

  const [text, setText] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const placeholderText = "No text description yet..."

  let isEditable = (window.location.hostname === 'localhost');

  useEffect(() => {
    let initial_text
    if ((tensorId in globals.feature_descriptions) && (channelIx in globals.feature_descriptions[tensorId])) {
      initial_text = globals.feature_descriptions[tensorId][channelIx].body
    } else {
      initial_text = placeholderText
    }
    setText(initial_text)

  }, [tensorId, channelIx]);

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setText(newText);
    setIsDirty(newText !== text);
  };

  const handleSave = () => {
    utils.save_description_text(tensorId, channelIx, text)
    setIsDirty(false);
  };

  const handleFocus = () => {
    if (!isEditable) return;
    if (text === placeholderText) {
      setText('');
      setIsDirty(true);
    }
  };

  const handleBlur = () => {
    if (!isEditable) return;
    if (text.trim() === '') {
      setText(placeholderText);
      setIsDirty(false);
    }
  };

  return (
    <div style={{
      height:"100%",
      width:"100%",
      position: 'relative'
    }}>
      <textarea
        value={text}
        onChange={handleTextChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        readOnly={!isEditable}
        style={{
          width: '100%',
          padding: '0px',
          border: '0px solid #d1d5db',
          borderRadius: '6px',
          // marginBottom: '8px',
          height: '100%',
          outline: 'none',
          fontFamily: 'inherit',
          fontSize:'12px',
          resize: 'none',
          overflowY: 'auto',
          cursor: isEditable ? 'text' : 'default',
        }}
      />
      {isDirty && (
        <button
          onClick={handleSave}
          style={{
            backgroundColor: 'grey',
            color: 'white',
            border: 'none',
            borderRadius: '6px',
            cursor: 'pointer',
            position: 'absolute',
            bottom: '0px',
            right: '0px',
          }}
        >
          Save
        </button>
      )}
    </div>
  );
};

export default FeatureDescriptionTextEditor;